export const BILL_TYPES = [
  {id: 0, value: "0", label: "Lỗi"}, 
  {id: 1, value: "1", label: "Xuất ngay"}, 
  {id: 2, value: "2", label: "Xuất nháp"}, 
  {id: 3, value: "3", label: "Không xuất"}, 
  {id: 4, value: "4", label: "Rà soát lại"}, 
]

export const FUEL_TYPE = [
  {id: 0, value: "XĂNG RON 92", label: "XĂNG RON 92"},
	{id: 1, value: "XĂNG RON 92 - II", label: "XĂNG RON 92 - II"},
	{id: 2, value: "XĂNG RON 95", label: "XĂNG RON 95"},
	{id: 3, value: "XĂNG RON 95 - II", label: "XĂNG RON 95 - II"},
	{id: 4, value: "XĂNG RON 95 - III", label: "XĂNG RON 95 - III"},
	{id: 5, value: "XĂNG RON 95 - IV", label: "XĂNG RON 95 - IV"},
	{id: 6, value: "XĂNG RON 95 - V", label: "XĂNG RON 95 - V"},
	{id: 7, value: "XĂNG RON 97 - IV", label: "XĂNG RON 97 - IV"},
	{id: 8, value: "XĂNG E5 RON 92 - II", label: "XĂNG E5 RON 92 - II"},
	{id: 9, value: "DẦU DO 0,05S", label: "DẦU DO 0,05S"},
	{id: 10, value: "DẦU DO 0,05S - II", label: "DẦU DO 0,05S - II"},
	{id: 11, value: "DẦU DO 0,05S - III", label: "DẦU DO 0,05S - III"},
	{id: 12, value: "DẦU DO 0,05%S", label: "DẦU DO 0,05%S"},
	{id: 13, value: "DẦU DO 0,05%S - II", label: "DẦU DO 0,05%S - II"},
	{id: 14, value: "DẦU DO 0,05%S - III", label: "DẦU DO 0,05%S - III"},
	{id: 15, value: "DẦU DO 0,001S", label: "DẦU DO 0,001S"},
	{id: 16, value: "DẦU DO 0,0015 - V", label: "DẦU DO 0,0015 - V"},
	{id: 17, value: "DẦU HỎA 2-K", label: "DẦU HỎA 2-K"},
	{id: 18, value: "Chưa phân loại", label: "Chưa phân loại"},
]

export const PUMP_ID = [
  {id: 0, value: "0", label: "0"},
  {id: 1, value: "1", label: "1"},
  {id: 2, value: "2", label: "2"},
  {id: 3, value: "3", label: "3"},
  {id: 4, value: "4", label: "4"},
  {id: 5, value: "5", label: "5"},
  {id: 6, value: "6", label: "6"},
  {id: 7, value: "7", label: "7"},
  {id: 8, value: "8", label: "8"},
  {id: 9, value: "9", label: "9"},
  {id: 10, value: "10", label: "10"},
  {id: 11, value: "11", label: "11"},
  {id: 12, value: "12", label: "12"},
  {id: 13, value: "13", label: "13"},
  {id: 14, value: "14", label: "14"},
  {id: 15, value: "15", label: "15"},
]